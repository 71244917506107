import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import RoomMock from "../components/RoomMock"
import VideoSection from "../components/VideoSection"
import ClownSection from "../components/ClownSection"
import RoomContentSection from "../components/RoomContentSection"

export default function RoomTemplate1({
  data: {
    allClownsYaml,
    allVideosYaml,
    ciceronYaml,
  },
  pageContext: {
    lang,
    pagePath,
    translations,
  },
}) {
  const intl = useIntl()
  const clownHospitalVideos = allVideosYaml.nodes.find((x) => x._id === "clown-hospital")
  const gizmosGadgetsVideos = allVideosYaml.nodes.find((x) => x._id === "gizmos-gadgets")
  const catwalkVideos = allVideosYaml.nodes.find((x) => x._id === "catwalk")

  return (
    <RoomMock
      lang={lang}
      path={pagePath}
      room={1}
      title={intl.formatMessage({ id: "roomHeading1" })}
      shareImage={{
        url: `/assets/share-images/NW_Delning3_Clownsjkh_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
        width: 1200,
        height: 630,
      }}
      ciceronData={ciceronYaml}
      translations={translations}
    >
      <RoomContentSection heading={intl.formatMessage({ id: "clownMedicineHeading" })}>
        <ClownSection clowns={allClownsYaml.nodes} />
      </RoomContentSection>

      <RoomContentSection>
        <VideoSection lang={lang} title={clownHospitalVideos.title} videos={clownHospitalVideos.videos} />
      </RoomContentSection>

      <RoomContentSection>
        <VideoSection lang={lang} title={gizmosGadgetsVideos.title} videos={gizmosGadgetsVideos.videos} />
      </RoomContentSection>

      <RoomContentSection>
        <VideoSection lang={lang} title={catwalkVideos.title} videos={catwalkVideos.videos} />
      </RoomContentSection>
    </RoomMock>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    allClownsYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }) {
      nodes {
        clown_name
        text {
          html
        }
        bgColor
        thumb
        frame
        image
        imageAltText
      }
    }
    allVideosYaml(filter: { lang: { eq: $lang }, page: { eq: "Clownsjukhuset" } }) {
      nodes {
        _id
        title
        videos {
          id
          provider
          title
          image
          audio_description
          chapters {
            title
            image
            time
          }
        }
      }
    }
    ciceronYaml(lang: { eq: $lang }, _id: { eq: "rum-1" }) {
      text
      sound
    }
  }
`
