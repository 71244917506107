import React from "react"
import styled from "styled-components"

import { useModal } from "../hooks/modal"

import RoomContentScroller from "../components/RoomContentScroller"
import MediaThumbnail from "./MediaThumbnail"

const StyledMediaThumbnail = styled(MediaThumbnail)`
  > button {
    background-size: 87% 87%;

    &::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      padding: 0;
      border: 0;
      background: url(${props => props.frame}) no-repeat 0 0;
    }
  }
`

const ClownSection = ({ clowns }) => {
  const { openModal } = useModal()

  return (
    <RoomContentScroller
      shape="circle"
      items={clowns.map((clown) => (
        ({ lazyBp }) => (
          <StyledMediaThumbnail
            title={clown.clown_name}
            background={encodeURI(clown.thumb)}
            frame={clown.frame}
            isRound
            onClick={(e) => openModal(e, "flipCard", { clown })}
            lazyBp={lazyBp}
            imageTransform
          />
        )
      ))}
    />
  )
}

export default ClownSection
